/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://react-typescript-cheatsheet.netlify.app/docs/basic/setup"
  }, "React TypeScript CheatSheet")), "\n", React.createElement(_components.h2, null, "React Props Cheatsheet"), "\n", React.createElement(_components.h3, null, "Ignore Type Errors"), "\n", React.createElement(_components.h4, null, "Ignore TypeScript Errors on File"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ts"
  }, "// eslint-disable-next-line @typescript-eslint/ban-ts-comment\n// @ts-nocheck\n")), "\n", React.createElement(_components.h4, null, "Ignore TypeScript Errors on Next Line"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ts"
  }, "// eslint-disable-next-line @typescript-eslint/ban-ts-comment\n// @ts-ignore\n")), "\n", React.createElement(_components.h3, null, "Basic Props Types"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ts"
  }, "type AppProps = {\n  message: string\n  count: number\n  disabled: boolean\n}\n")), "\n", React.createElement(_components.h3, null, "Arrays And Object Props Tyes"), "\n", React.createElement(_components.h4, null, "Array"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ts"
  }, "type AppProps = {\n  names: string[]\n}\n")), "\n", React.createElement(_components.h4, null, "String Litteral Exact Values"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ts"
  }, "type AppProps = {\n  status: \"loading\" | \"success\" | \"error\"\n}\n")), "\n", React.createElement(_components.h3, null, "Objects"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ts"
  }, "type AppProps = {\n  /** interface with no required properties **/\n  obj: {}\n  /** useful placeholder **/\n  obj2: object\n}\n")), "\n", React.createElement(_components.h4, null, "Single Object Multiple Types"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ts"
  }, "type AppProps = {\n  obj: {\n    lkes: number\n    id: string\n    title: string\n    description: string\n  }\n}\n")), "\n", React.createElement(_components.h4, null, "Array of Objects"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ts"
  }, "type AppProps = {\n  objArr: {\n    id: string\n    title: string\n    description: string\n  }[]\n}\n")), "\n", React.createElement(_components.h4, null, "Dictionaries"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ts"
  }, "type AppProps = {\n  dict: {\n    [key: string]: TypeHere\n  }\n}\n")), "\n", React.createElement(_components.p, null, "or"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ts"
  }, "type AppProps = {\n  dict: Record<string, TypeHere>\n}\n")), "\n", React.createElement(_components.h3, null, "Function Types"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ts"
  }, "type AppProps = {\n  onClick: () => void\n}\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ts"
  }, "type AppProps = {\n  onClick: (id: number) => void\n}\n")), "\n", React.createElement(_components.h4, null, "Function Type with Event"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ts"
  }, "type AppProps = {\n  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void\n  /**alternative**/\n  onClick: (event: React.MouseEvent<HTMLInputElement>) => void\n}\n")), "\n", React.createElement(_components.h3, null, "Other types"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ts"
  }, "type AppProps = {\n  optional?: OptionalType\n}\n")), "\n", React.createElement(_components.h3, null, "setState type"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ts"
  }, "type AppProps = {\n  setState: React.Dispatch<React.SetStateAction<number>>\n}\n")), "\n", React.createElement(_components.h3, null, "Typing React Component as Children"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "npm i @types/react\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-tsx"
  }, "type FooProps = {\n  children: React.ReactNode\n}\n\nexport const Foo = ({ children }: FooProps) => {\n  return <div>{children}</div>\n}\n")), "\n", React.createElement(_components.h2, null, "Examples"), "\n", React.createElement(_components.h2, null, "Simple Example of Baisc Prop Types"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ts"
  }, "//....\nconst App = () => {\n  return (\n      <div>\n        <Greet name=\"Ben\"/>\n        <MessageCount count={5}>\n        <DarkToggle isDark={true}>\n    </div>\n    )\n}\n\n")), "\n", React.createElement(_components.h3, null, React.createElement(_components.code, null, "string"), " type"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ts"
  }, "type GreetProps = {\n  name: string\n}\nexport const Greet = (props: GreetProps) => {\n  return (\n    <div>\n      <h3>Welcome to React TypeScript {props.name}</h3>\n    </div>\n  )\n}\n")), "\n", React.createElement(_components.p, null, "or"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ts:title=component/greet.tsx"
  }, "type GreetProps = {\n    name: string\n}\nexport const Greet = ({name}: GreetProps) => {\n    return (\n        <div>\n            <h3>Welcome to React TypeScript {props.name}\n        </div>\n    )\n}\n")), "\n", React.createElement(_components.h3, null, React.createElement(_components.code, null, "number"), " Type"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ts:title=component/message.tsx"
  }, "type MessageProps = {\n  count: number\n}\nexport const Greet = ({ count }: MessageProps) => {\n  return (\n    <div>\n      <p>You have {count} messages</p>\n    </div>\n  )\n}\n")), "\n", React.createElement(_components.h3, null, React.createElement(_components.code, null, "boolean"), " Type"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ts:title=component/DarkToggle.tsx"
  }, "type DarkToggleProps = {\n  isDark: boolean\n}\nexport const Greet = ({ isDark }: DarkToggleProps) => {\n  return (\n    <div>\n      <p>You are using dark mode {isDark ? \"true\" : \"false\"}</p>\n    </div>\n  )\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
